<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <div class="headline">Registrar empresa</div>
      </v-col>
    </v-row>
    <EmpresaForm
      :empresa="empresa"
      :provincias="provincias"
      :isUpdate="false"
      :isLoading="isLoading"
      @process-empresa="registraEmpresa"
    ></EmpresaForm>
  </v-container>
</template>

<script>
import EmpresaForm from "@/components/empresa/empresa.form.vue";
import EmpresaAPI from "@/api/empresas";

export default {
  name: "empresa.update",
  components: {
    EmpresaForm,
  },
  

  data() {
    return {
      provincias: [],
      isLoading: false,
      empresa: {
            razonSocial: "",
            nombreFantasia: "",
            fecha_inicio_actividades: null,
            direccion: "",
            email: "",
            cuit: "",
            localidad: { id: null },
        }
    };
  },

  methods: {
    async registraEmpresa(empresa) {
      try {
        this.isLoading = true;
        const response = await EmpresaAPI.create(empresa);
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: `Registrada`,
          icon: "mdi-check",
          timeout: 2500,
        });
        this.isLoading = false;
      } catch (error) {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "info",
          text: `Problema al registrar la empresa'`,
          icon: "mdi-bug",
          timeout: 2500,
        });
        this.isLoading = false;
      }
    },
    async fetchProvincias() {
      try {
        const provincias = await this.axios.get("/soporte/provincias");

        this.provincias = provincias.data;
      } catch (error) {}
    },
  },
  mounted() {
    this.fetchProvincias();
  },
};
</script>

<style lang="scss" scoped></style>
